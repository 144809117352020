import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectHeader from '../components/ProjectHeader'
import ProjectDescription from '../components/ProjectDescription'
import ProjectGrid from '../components/ProjectGrid'
import ProjectNextLink from '../components/ProjectNextLink'
import InstagramFeed from '../components/InstagramFeed'
import ContactBoxes from '../components/ContactBoxes'
import Footer from '../components/Footer'

import headerRightBanner from '../images/projects/casa-cond-verde/header-right-banner.jpg'
import projecPhoto1 from '../images/projects/casa-cond-verde/apt-template-1.jpg'
import projecPhoto2 from '../images/projects/casa-cond-verde/apt-template-2.jpg'
import projecPhoto3 from '../images/projects/casa-cond-verde/apt-template-3.jpg'
import projecPhoto4 from '../images/projects/casa-cond-verde/apt-template-4.jpg'
import projecPhoto5 from '../images/projects/casa-cond-verde/apt-template-5.jpg'
import projecPhoto6 from '../images/projects/casa-cond-verde/apt-template-6.jpg'
import projecPhoto7 from '../images/projects/casa-cond-verde/apt-template-7.jpg'

import '../styles/global.scss'

const dynamicInfos = {
  headerImage: headerRightBanner,
  title: 'Casa',
  subTitle: 'Condomínio Verde',
  typeService: 'PROJETO',
  duration: 'andamento',
  deliverDate: '2019',
  local: 'Brasília',
  color: '#46b7d8',
  description: (
    <Fragment>
      <p>
        Projeto de <b>residência</b> em fase de andamento no StudioM4. Um
        projeto de arquitetura contemporânea para um casal jovem e seu filhinho
        que está pra chegar, a casa tem tudo que a família precisa, mas sem
        excessos, com ambientes de tamanhos racionais para viabilizar a
        construção <b>dentro do orçamento</b> disponível.
      </p>
    </Fragment>
  ),
  photosFirstRow: [
    { id: 'gap1', gap: true, className: 'dimensionGap' },
    { id: '01', image: projecPhoto1, className: 'dimensionOption1' },
    { id: '02', image: projecPhoto4, className: 'dimensionOption2' },
    { id: '03', image: projecPhoto3, className: 'dimensionOption3' },
    { id: '04', image: projecPhoto2, className: 'dimensionOption4' }
  ],
  photosSecondRow: [
    { id: '01', image: projecPhoto5, className: 'dimensionOption5' },
    { id: '02', image: projecPhoto6, className: 'dimensionOption5' },
    { id: '03', image: projecPhoto7, className: 'dimensionOption6' },
    { id: 'gap1', gap: true, className: 'dimensionGap' }
  ],
  nextProject: 'apartamento-103-nw'
}

const IndexPage = () => (
  <Layout>
    <SEO
      title="Casa Condomínio Verde"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <ProjectHeader
      headerImage={dynamicInfos.headerImage}
      title={dynamicInfos.title}
      subTitle={dynamicInfos.subTitle}
    />
    <ProjectDescription
      typeService={dynamicInfos.typeService}
      duration={dynamicInfos.duration}
      deliverDate={dynamicInfos.deliverDate}
      local={dynamicInfos.local}
      description={dynamicInfos.description}
      color={dynamicInfos.color}
    />
    <ProjectGrid
      photosFirstRow={dynamicInfos.photosFirstRow}
      photosSecondRow={dynamicInfos.photosSecondRow}
    />
    <ProjectNextLink nextProject={dynamicInfos.nextProject} />
    <InstagramFeed />
    <ContactBoxes />
    <Footer />
  </Layout>
)

export default IndexPage
